@import "../../style/_variables";
@import "../../style/_viewports";

.menu-trigger {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: rgb(2,0,36);
  background: linear-gradient(324deg, rgba(2,0,36,1) 0%, rgba(12,12,181,1) 32%, rgba(0,212,255,1) 100%);

  @include viewports(above small) {
    display: none;
  }

  @include viewports(up-to small) {
    border-radius: 50%;
    margin: 3px;
  }

  img {
    width: 35px;
  }
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;

  @include viewports(up-to small) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 0;
    z-index: 13;
    width: 290px;
    height: 100%;
    background: rgb(2,0,36);
    background: linear-gradient(324deg, rgba(2,0,36,1) 0%, rgba(12,12,181,1) 32%, rgba(0,212,255,1) 100%);
    overflow: auto;
    box-shadow: 3px 0 6px rgba(#000, 0.1);
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.5s ease-in-out;
  }

  &--open {
    transform: translate3d(0, 0, 0);
  }

  &--fixed {
    @include viewports(above small) {
      position: fixed;
      z-index: 10;
      background: $color-blue;
      background: linear-gradient(324deg, rgba(2,0,36,1) 0%, rgba(12,12,181,1) 32%, rgba(0,212,255,1) 100%);
      margin-top: 0;
      box-shadow: 0 2px 4px rgba(#000, 0.1);
    }

    .container {
      @include viewports(above small) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
      }

      @include viewports(up-to medium) {
        justify-content: center;
      }
    }
  }

  &__close {
    width: 60px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-top: 10px;

    @include viewports(above small) {
      display: none;
    }

    img {
      width: 80%;
    }
  }

  &__logo {
    display: block;
    width: 120px;

    @include viewports(up-to medium) {
      display: none;
    }
  }

  &__main {
    display: flex;
    padding: 0;
    margin: 0;

    @include viewports(up-to small) {
      flex-direction: column;
    }

    > li {
      display: flex;
      align-items: center;
      list-style: none;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      position: relative;
      padding: 20px;
      white-space: nowrap;

      @include viewports(up-to small) {
        flex-direction: column;
        padding: 0;
        border-bottom: 1px solid rgba(#000, 0.1);
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        .menu__main__submenu {
          pointer-events: all;
          transform: translate3d(0, 0, 0) scale(1);
          opacity: 1;
        }

        > img {
          transform: rotate(180deg);
        }
      }

      > a {
        color: inherit;
        text-decoration: none;

        @include viewports(up-to small) {
          display: block;
          padding: 20px;
          text-align: left;
          width: 100%;
        }
      }

      > img {
        display: block;
        margin-left: 5px;
        transition: transform 0.3s ease-in-out;

        @include viewports(up-to small) {
          display: none;
        }
      }

      > span {
        @include viewports(up-to small) {
          display: block;
          padding: 20px;
          text-align: left;
          width: 100%;
        }
      }
    }

    &__submenu {
      padding: 0;
      margin-top: 0;

      @include viewports(above small) {
        position: absolute;
        left: 50%;
        top: 100%;
        margin-left: -150px;
        width: 300px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 5px 15px rgba(#000, 0.2), 0 2px 4px rgba(#000, 0.1);
        pointer-events: none;
        opacity: 0;
        transform: translate3d(0, -10px, 0) scale(0.98);
        transition: all 0.3s cubic-bezier(.02,.7,.4,1);
        transform-origin: top;
      }

      @include viewports(up-to small) {
        width: 100%;
        // background: rgba(#fff, 0.3);
        color: #fff;
      }

      &:before {
        @include viewports(above small) {
          content: '';
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -10px;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: #fff;
        }
      }

      &--small {
        @include viewports(above small) {
          width: 60px;
          margin-left: -30px;
          padding-top: 5px;
        }

        li {
          text-align: center;
          padding: 5px;
        }
      }

      li {
        margin-right: 0;
        list-style: none;
        
        &:first-child {
          a {
            margin-top: 5px;
          }
        }
  
        &:last-child {
          a {
            border-radius: 0 0 5px 5px;
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        min-height: 45px;
        padding: 10px 15px;
        text-decoration: none;
        color: $color-black;
        font-size: 1.6rem;

        @include viewports(up-to small) {
          color: #fff;
          font-size: 1.4rem;
          padding-left: 30px;
        }

        &:hover {
          background: $color-blue;
          background: linear-gradient(324deg, rgba(2,0,36,1) 0%, rgba(12,12,181,1) 32%, rgba(0,212,255,1) 100%);
          color: #fff;

          img {
            filter: brightness(0) invert(1);
          }
        }

        img {
          width: 20px;
          height: auto;
          margin-right: 15px;

          @include viewports(up-to small) {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}

.menu-flag {
  border: 1px solid #fff;
  border-radius: 3px;
}