.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Yarı saydam arka plan */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #0e1d36; /* Mavi tonlarında bir arka plan */
  color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  position: relative;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.modal-icon {
  background-color: #ffa500; /* Uyarı simgesi için turuncu ton */
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  color: #0e1d36;
  margin: 0 auto 20px auto;
}

.modal-title {
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 10px;
}

.modal-message {
  font-size: 1em;
  margin-bottom: 20px;
}

.modal-button {
  background-color: #ffffff;
  color: #0e1d36;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #f2f2f2;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}
