@import "../../style/_variables";
@import "../../style/_viewports";

.footer-wrapper {
  overflow: hidden;
  position: relative;

  &__dark_bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 454px;
    background: $color-black;
  }
  
  &__subscribe {
    position: relative;
    z-index: 2;

    .title {
      margin-bottom: 15px;
      margin-top: 45px;
    }

    p {
      text-align: center;
      color: #fff;
      font-weight: 300;
      font-size: 1.6rem;
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 50px;
      margin: 30px auto 50px;
      border: 2px solid #fff;
      border-radius: 100px;
      text-decoration: none;
      color: $color-blue;
      transition: all 0.2s linear;

      &:hover {
        background: #fff;
        color: $color-black;
      }
    }
  }

  .footer {
    position: relative;
    z-index: 2;
    display: flex;
    background: #fff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(#000, 0.2);
    

    @include viewports(up-to tiny) {
      flex-direction: column;
      align-items: center;
      padding: 15px;
    }

    &__twitter {
      @include viewports(up-to tiny) {
        display: none;
      }
    }

    &__links {
      flex: 1;
      display: flex;
      margin-left: 20px;

      @include viewports(up-to tiny) {
        flex-wrap: wrap;
        margin-left: 0;
      }

      &__list {
        flex: 1;
        margin: 0 10px;
        padding: 0;
        list-style: none;

        @include viewports(up-to tiny) {
          max-width: 45%;
          min-width: 45%;
          margin: 10px 2.5%;
        }

        &__title {
          font-size: 19px;
          color: $color-blue;
          margin-bottom: 10px;
          margin-top: 10px;

          a {
            color: inherit;
            text-decoration: none;
          }
        }

        li {
          &:not(.footer__links__list__title) {
            a {
              display: block;
              color: $color-grey;
              text-decoration: none;
              padding: 2px 0;
              margin: 2px 0;
              font-size: 1.4rem;
              line-height: 2rem;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  &__copyright {
    color: rgba($color-black, 0.4);
    font-size: 14px;
    padding: 35px 0;
    text-align: center;
  }
}