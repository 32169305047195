@import "../../style/_variables";

.faq {
  h4 {
    font-size: 2rem;
    color: $color-blue;
    font-weight: 300;
    margin: 10px 0;
  }

  p {
    display: list-item;
    list-style: disc;
    margin-bottom: 30px;
  }
}