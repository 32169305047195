@import "../../style/_variables";

.PrivacyPage {
  .container {
    max-width: 960px;
  }

  h1 {
    color: $color-blue;
    font-weight: 300;
    font-size: 2.8rem;
    margin-bottom: 0;
  }

  h2 {
    font-size: 20px;
    font-weight: 300;
    color: $color-blue;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    color: #212529;
  }

  ul {
    li {
      font-weight: 300;
    }
  }
}